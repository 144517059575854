import * as React from "react";
import Layout from "../components/Layout";
import { HtmlHead, ResponsiveImage, Link } from "@cdv/jazz-web/components";
import { Typography, Grid, Container, Box } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export const Head = () => (
  <HtmlHead title="Kontakt | Metodické centrum pro dopravní psychologii">
    <meta name="foo" content="bar" />
  </HtmlHead>
);

export default function Page() {
  const aCss = {
    a: {
      textDecoration: "none",
      color: "#e05206",
      borderBottom: "1px solid transparent",
      transitionDuration: "0.3s",
    },
    "a:hover": {
      borderBottom: "1px solid #e05206",
      transitionDuration: "0.3s",
    },
  };
  return (
    <Layout isIndex={false}>
      <Container sx={aCss}>
        <Typography variant="h1">Kontakty</Typography>
        <Grid container spacing={4}>
          <Grid item md={6}>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Typography variant="h3" sx={{ fontSize: "24px" }}>
                Adresa
              </Typography>
              <Typography variant="h4" sx={{ textAlign: "center" }}>
                <strong>
                  Metodické centrum pro dopravní psychologii <br /> Centrum dopravního výzkumu , v.
                  v. i.
                </strong>
              </Typography>
              <Typography>Líšeňská 33a </Typography>
              <Typography>636 00 Brno</Typography>
              <Typography>
                <Link to="mailto:metodicke.centrum@cdv.cz">metodicke.centrum@cdv.cz</Link>
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Typography variant="h3" sx={{ fontSize: "24px" }}>
                Kontaktní osoba
              </Typography>
              <Box>
                <Typography variant="h4">
                  <strong>Mgr. Martina Trepáčová</strong>
                </Typography>
                <Typography>
                  <Link to="mailto:martina.trepacova@cdv.cz">martina.trepacova@cdv.cz</Link>
                </Typography>
              </Box>
              <Box>
                <Typography variant="h4">
                  <strong>Mgr. Veronika Kurečková</strong>
                </Typography>
                <Typography>
                  <Link to="mailto:veronika.kureckova@cdv.cz">veronika.kureckova@cdv.cz</Link>
                </Typography>
              </Box>
              <Box>
                <Typography variant="h4">
                  <strong>Mgr. Tomáš Rosenberg</strong>
                </Typography>
                <Typography>
                  <Link to="mailto:tomas.resenberg@cdv.cz">tomas.resenberg@cdv.cz</Link>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Box sx={{ marginTop: "120px" }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10428.612369309425!2d16.6613634!3d49.1976553!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3f451feafea35261!2sTransport%20Research%20Center%20v.%20V.%20I.!5e0!3m2!1ssk!2scz!4v1666866300648!5m2!1ssk!2scz"
          width="100%"
          height="450px"
        ></iframe>
      </Box>
    </Layout>
  );
}
